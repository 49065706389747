import React from "react";
import "./authorization.scss";
import $ from "jquery";
import AuthorizationWindow from "./AuthorizationWindow";
import ActionParser from "../technical/ActionParser.mjs";

export default class Authorization extends React.Component {
    constructor(_props) {
        super(_props);

        this.state = {
            current_user: this.props.current_user,
            mode: null,
            windowsStack: [],
            currentWindow: null
        }

        this.typeName = "Authorization";
        this.triggerMode = this.triggerMode.bind(this);
        this.resetState = this.resetState.bind(this);
        this.demo = this.demo.bind(this);
        this.getAuthorizationWindow = this.getAuthorizationWindow.bind(this);
        this.openWindow = this.openWindow.bind(this);
        this.callback = this.callback.bind(this);
        this.signIn = this.signIn.bind(this);

    }

    componentDidMount() {
        this.getAuthorizationWindow(this.props.server_url);
    }

    signIn(user) {
        // alert(`Signing in ${JSON.stringify(user)}`);
        this.props.signIn(user);
    }

    callback(action) {
        ActionParser.parseAction(action, this);
    }

    getAuthorizationWindow(url) {
        let me = this;
        $.ajax({
            url: url,
            dataType: "json",
            success: (data) => {
                let prevState = {... me.state};
                // alert(data);
                prevState.currentWindow = data;
                // alert(JSON.stringify(data));
                prevState.windowsStack.push(data);
                me.setState(prevState);
            },
            error: (e) => {
                // alert(e);
                let data = me.props.cacheStorageForData.authorizationWindowData[url];
                if (!data) {
                    me.props.showToast("Ошибка связи", "error");
                    return;
                }
                let prevState = {... me.state};
                prevState.currentWindow = data;
                // alert(JSON.stringify(data));
                prevState.windowsStack.push(data);
                me.setState(prevState);
            }
        })
    }

    openWindow(newWindow) {

    }

    triggerMode(mode) {
        let prevState = {... this.state};
        prevState.mode = mode;
        this.setState(prevState);
    }

    resetState() {
        let prevState = {... this.state};
        prevState.mode = null;
        this.setState(prevState);
    }

    demo() {
        this.props.setCurrentUser({});
    }

    render() {
        let me = this;
        if (me.props.user) {
            return <></>;
        }
        return (<div key={`window-${me.state.currentWindow ? me.state.currentWindow.id : "---"}`} style={{overflowY: !!me.state.mode ? 'scroll' : "hidden"}} className={"authorization"}>
            {!me.state.currentWindow &&
                <p>Загрузка</p>
            }

            {me.state.currentWindow &&
                <AuthorizationWindow showToast={me.props.showToast} signIn={me.signIn} callback={me.callback} window_data={me.state.currentWindow} />
            }
        </div>);
    }
}