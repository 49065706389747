import React from "react";
import "./button.scss";

export default class Button extends React.Component {
    constructor(props) {
        super(props);
        this.parseAction = this.parseAction.bind(this);

        this.typeName = "Button";
    }

    parseAction() {
        let me = this;
        // alert(JSON.stringify(me.props.data));
        me.props.callback(me.props.data.action);
    }

    render() {
        let me = this;
        let isDisabled = !me.props.data.action || !!me.props.data.disabled;

        return (<button onClick={ (e) => {me.parseAction();} } className={`${me.props.data.button_type} ${isDisabled ? 'disabled' : ''} ${me.props.data.align_left ? 'align-left' : ''}`}>
            { "back_button" == me.props.data.button_type &&
                <i className={"fal fa-angle-left fa-2x move-left"} />
            }
            { !!me.props.data.fa_icon &&
                <i className={`${me.props.data.fa_icon} move-left`} />
            }
            <p>{me.props.data.caption}</p>
            {me.props.data.show_arrow &&
                <i className={"fal fa-angle-right fa-2x"} />
            }
            {me.props.data.show_rounded_arrow &&
                <div className={"fa-rounded"}>
                    <i className={"far fa-angle-right fa-2x"} />
                </div>
            }
            </button>);
    }

}