import React from "react";
import "./news-carousel.scss";
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import $ from 'jquery';

export default class NewsCarousel extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            news: [],
            activeNew: null,
            intervalId: null,
            hideMe: false
        };

        this.typeName = "NewsCarousel";
        this.updateNews = this.updateNews.bind(this);
        this.closeDialog = this.closeDialog.bind(this);
        this.openNewsView = this.openNewsView.bind(this);
        this.setInVisibility = this.setInVisibility.bind(this);
    }

    componentDidMount() {
        this.updateNews();
        let intervalId = setInterval(this.updateNews, 5000);
        let prevState = {... this.state};
        prevState.intervalId = intervalId;
        this.setState(prevState);
    }

    componentWillUnmount() {
        clearInterval(this.state.intervalId);
    }

    async setInVisibility(_invisibility) {
        let me = this;
        return new Promise((resolve, reject) => {
            let prevState = {... me.state};
            prevState.hideMe = _invisibility;
            me.setState(prevState, () => {
                resolve(true);
            });
        });
    }

    updateNews() {
        let me = this;
        if (!me.props.user) return;
        let url = `${this.props.server_url}`;
        let data = {
            token: me.props.user.token
        }
        $.ajax({
                url: url,
                method: "post",
                dataType: "json",
                data: data,
                success: (e) => {
                    let prevState = {... me.state};
                    prevState.news = e;
                    me.setState(prevState);
                },
                error: (e) => {
                    let prevState = {... me.state};
                    prevState.news = me.props.cacheStorageForData.newsCarouselData.newsItems;
                    // alert(prevState.news.length);
                    me.setState(prevState);
                }
            }
        );
    }

    openNewsView(e) {
        // alert(JSON.stringify(e));
        if (e.src_href) {
            let target = !e.src_target ? "_system" :  e.src_target;
            try{
                window.inAppBrowser.open(e.src_href, target, "location=no");
            } catch(e) {
                // let loader = document.querySelector("#loader");
                // loader.style.display = 'flex';
                // setTimeout(() => {loader.style.display = 'none'}, 2000)
            }
            // window.open(e.src_href, target);
            return;
        }

        let prevState = {... this.state};
        prevState.activeNew = e;
        try {
            window.TapticEngine.impact({
                style: "medium" // light | medium | heavy
            });
        } catch(e) {

        }
        this.setState(prevState);
    }


    closeDialog() {
        let prevState = {... this.state};
        prevState.activeNew = null;
        try {
            window.TapticEngine.impact({
                style: "light" // light | medium | heavy
            });
        } catch(e) {

        }
        this.setState(prevState);
    }

    render() {
        let me = this;
        if (!me.props.user || !!me.props.hideNews || me.props.hide_me || me.state.hideMe) {
            return <></>;
        }
        return (<>
            <h2 style={{fontSize: "var(--font_size_3)",marginLeft: "8px", marginTop: "17px"}}>Актуальное</h2>
            <div className="news-carousel" style={{display: !this.props.hideNews ? "flex" : 'none'}}>
                <div style={{display: "flex", minWidth: "7px"}}></div>
                {me.state.news.map( (e,i) =>
                    <div onClick={(ev) => { me.openNewsView(e); }} className="news-item" key={`news-${e.id}-${i}`}>
                        <img src={`${e.pic_url}`} />
                        <p>{e.title}</p>
                        <p className={"call-to-action"}>
                            {e.call_to_action}
                            <i style={{marginLeft: "auto", color: "#fff"}} className={"fal fa-angle-right"}></i>
                        </p>
                        <div className={"toner"}></div>
                    </div>
                )}
                <div style={{display: "flex", minWidth: "37px"}}></div>
                <Dialog onClose={me.closeDialog} aria-labelledby="customized-dialog-title" open={!!me.state.activeNew} >
                    <DialogContent>
                        {!!me.state.activeNew &&
                            <div dangerouslySetInnerHTML={{__html: me.state.activeNew.content}}></div>
                        }
                    </DialogContent>
                </Dialog>
            </div>
        </>);
    }
}