import React from "react";
import {FormControl, OutlinedInput , InputLabel, TextField} from "@material-ui/core";
import {IMaskInput} from "react-imask";
import "./field-input.scss";
import $ from 'jquery';

export default class FieldInput extends React.Component {

    constructor(_props) {
        super(_props);
        let me = this;
        this.state = {
            value: this.props.data.value ? this.props.data.value : ""
        };
        this.typeName = "FieldInput";
        this.handleChange = this.handleChange.bind(this);
        this.triggerFeedback = this.triggerFeedback.bind(this);
        this.triggerInteraction = this.triggerInteraction.bind(this);
        this.getKeyValueAsArr = this.getKeyValueAsArr.bind(this);
        this.getValue = this.getValue.bind(this);
        this.textMaskCustom = React.forwardRef(function TextMaskCustom(props, ref) {
          const { onChange, ...other } = props;
          return (
            <IMaskInput
              {...other}
              mask={me.props.data.mask}
              inputRef={ref}
              onAccept={(value) => onChange({ target: { name: props.name, value, iMaskReady: false }})}
              onComplete={(value) => onChange({ target: { name: props.name, value, iMaskReady: true }})}
              overwrite
            />
          );
        });

        if (!me.props.handleInteraction) {
          throw {
            message: "Не задан handleInteraction для поля",
            code: 500
          }
        }
  }

  componentDidMount() {
      // let me = this;
      // me.handleChange({
      //   target: {
      //     name: me.props.name,
      //     value: me.props.data.value,
      //     iMaskReady: false
      //   }
      // })
  }

  getValue() {
    let me = this;
    return [me.props.data.field_name, me.state.value];
  }

    getKeyValueAsArr() {
      let me = this;
      return [me.props.data.field_name, me.state.value];
    }

    triggerFeedback() {
      let me = this;
      return new Promise((resolve, reject) => {
        // alert("Checking feedback!");
        let url = me.props.data.feedback_url;
        // alert(url);
        let data = {};
        data[me.props.data.field_name] = me.state.value;
        // alert(JSON.stringify(data));
        $.ajax({
          method: "post",
          url: url,
          dataType: "json",
          data: data,
          success: (res) => {
            resolve(res);
          },
          error: (res) => {
            // alert(res);
            resolve(false);
          }
        });
      });
    }

    triggerInteraction(payload) {
      let me = this;

      this.props.handleInteraction({
        field: me.props.data,
        value: me.state.value,
        interaction: me.props.data.interaction,
        payload: payload
      });
    }

    handleChange(ev) {
      // alert(312312);
        let me = this;
        if (me.state.value == ev.target.value) return;
        let prevState = {... me.state};
        prevState.value = ev.target.value;
        // alert(JSON.stringify(ev));
        me.setState(prevState, async () => {
          if (true || ev.target.iMaskReady || me.props.data.trigger_interaction_on_any_change) {
            if (me.props.data.trigger_feedback) {
              if (!me.props.data.trigger_feedback_button) {
                me.triggerFeedback().then((e) => {
                  if (e) {
                    if (!e.error) {
                      if (me.props.data.interaction) {
                        me.triggerInteraction(e);
                      }
                    } else {
                      if (e.error.length > 1) {
                        me.props.showToast(e.error, "error");
                      }
                    }
                  } else {
                    me.props.showToast("Ошибка сети", "error");
                  }
                });
              }
              return;
            }

            if (me.props.data.interaction) {
              await me.triggerInteraction(null);
            }
          }
        });
    }

    sayHi() {
      alert("HI");
    }

    render() {
        let me = this;
        let isHidden = !!me.props.data.hidden;
        let isDisabled = !!me.props.data.disabled
        let style = {
            transition: "500ms",
            background: "#fff",
            width: "95%",
            marginBottom: "16px",
            fontSize: "var(--font_size_4)",
            textAlign: "center",
            border: "2px solid var(--color-primary)",
            borderRadius: "6px"
          };

        if (isDisabled) {
          style.opacity = 0;
          style.pointerEvents = "none";
        }

        if (isHidden) {
          style.opacity = 0;
          style.pointerEvents = "none";
          style.position = "absolute";
        }

        return (
          <FormControl style={style} variant="standard">
              <InputLabel
                style={{
                  pointerEvents: "none",
                  zIndex: 10,
                  padding: "8px",
                  background: "var(--white)",
                  marginLeft: "8px",
                  marginTop: 0,
                  marginBottom: "auto",
                  top: "-12px"
                }} htmlFor="formatted-text-mask-input"
                shrink={(me.state.value && me.state.value.length > 0)}>{me.props.data.placeholder}</InputLabel>
              <OutlinedInput
                value={me.state.value}
                onChange={me.handleChange}
                name={me.props.data.field_name}
                variant={"outlined"}
                type={me.props.data.field_type}
                inputComponent={me.textMaskCustom}
              />
          </FormControl>
        );
    }
}
