import React from "react";
import Button from "../technical/button/Button";
import ActionParser from "../technical/ActionParser.mjs";
import FieldInput from "../helpers/FieldInput";

export default class AuthorizationWindowItem extends React.Component {
    constructor(_props) {
        super(_props);
        this.typeName = "AuthorizationWindowItem";
        this.callback = this.callback.bind(this);
        this.getKeyValueAsArr = this.getKeyValueAsArr.bind(this);
        this.fieldRef = React.createRef();
    }

    getKeyValueAsArr() {
        return this.fieldRef.current.getKeyValueAsArr();
    }

    callback(action) {
        this.props.callback(action);
    }

    render() {
        let me = this;
        return (
            <>
                { me.props.data.button &&
                    <Button callback={me.callback} data={me.props.data.button} />
                }
                { me.props.data.field_input &&
                    <FieldInput showToast={me.props.showToast} ref={me.fieldRef} handleInteraction={me.props.handleInteraction} callback={me.callback} data={me.props.data.field_input} />
                }
            </>
        );
    }
}