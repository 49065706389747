import React from "react";
import {Dialog, DialogContent, DialogTitle} from "@material-ui/core";
import ReactHtmlParser from "html-react-parser";

export default class MenuItemDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dialogOpen: false
    }

    this.handleClose = this.handleClose.bind(this);
    this.open = this.open.bind(this);
  }

  handleClose() {
    let prevState = {... this.state};
    prevState.dialogOpen = false;
    this.setState(prevState);
  }

  open() {
    let prevState = {... this.state};
    prevState.dialogOpen = true;
    this.setState(prevState);
  }

  render() {
    let me = this;
    return <div>
      <Dialog onClose={me.handleClose} open={me.state.dialogOpen}>
        <DialogContent style={{padding: 0}}>
          {
            ReactHtmlParser (
              me.props.data.content
            )
          }
        </DialogContent>
      </Dialog>
    </div>;
  }
}