import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';

let params = new URLSearchParams(document.location.search);
let hideNews = params.get("hide_news");
let hideHeader = params.get("hide_header");
let hideLowerMenu = params.get("hide_lower_menu");
let useAnonymAuth = params.get("use_anonym_auth");
let startMenuUrl = params.get("start_menu_url");
let useAlternativeLogo = params.get("use_alternative_logo");
let logoStyle = params.get("logo_style");
let disableToasts = params.get("disable_toasts");
// alert(`${hideNews} - ${hideHeader}`);
// alert(logoStyle);

ReactDOM.render(
  <React.StrictMode>
    <App hide_news={hideNews}
         hide_header={hideHeader}
         hide_lower_menu={hideLowerMenu}
         use_anonym_auth={useAnonymAuth}
         start_menu_url={startMenuUrl}
         use_alternative_logo={useAlternativeLogo}
         logo_style={logoStyle}
         disable_toasts={disableToasts}
    />
  </React.StrictMode>,
  document.getElementById('root')
);

