import React from "react";
import logo from "../logo.svg";
import AuthorizationWindowItem from "./AuthorizationWindowItem";
import ActionParser from "../technical/ActionParser.mjs";
import Logo from "../header/Logo";

export default class AuthorizationWindow extends React.Component {
    constructor(_params) {
        super(_params);
        this.state = {
            authorization_window_items: []
        };
        this.typeName = "AuthorizationWindow";
        this.callback = this.callback.bind(this);
        this.handleInteraction = this.handleInteraction.bind(this);
        this.serializeParcel = this.serializeParcel.bind(this);
        this.signIn = this.signIn.bind(this);
    }

    serializeParcel() {
        let data = {};
        let me = this;
        for(let item of me.state.authorization_window_items) {
            if (item.field_input) {
                let keyVal = item.ref.current.getKeyValueAsArr();
                data[keyVal[0]] = keyVal[1];
            }
        }
        return data;
    }

    signIn(user) {
        this.props.signIn(user);
    }

    componentDidMount() {
        let prevState = {... this.state};
        prevState.authorization_window_items = this.props.window_data.authorization_window_items.map((e, i) => {
            let newE = {...e};
            newE.ref = React.createRef();
            newE.myIndex = i;
            newE.interactionCounter = 0;
            return newE;
        });
        this.setState(prevState);
    }

    callback(action) {
        let me = this;

        if ("action_window" == action.context) {
            ActionParser.parseAction(action, me);
            return;
        }
        this.props.callback(action);
    }

    handleInteraction(parsel) {
        let me = this;
        if (parsel.payload) {
            if (parsel.payload.user) {
                me.signIn(parsel.payload.user);
                return;
            }
        }
        let fields = Object.keys(parsel.interaction);
        // alert(`Handling interaction: ${JSON.stringify(parsel.interaction)}`);
        // alert(fields);
        let touchedIndices = [];
        for(let field of fields) {
            let authorizationWindowItems = me.state.authorization_window_items.filter((x) => {
                for(let element in x) {
                    // alert(x[element].field_name);
                    if (field === x[element].field_name) {
                        return true;
                    }
                }
                return false;
            });
            let actions = parsel.interaction[field];
            // alert(authorizationWindowItems);
            for(let item of authorizationWindowItems) {
                console.log(item);
                for(let action of actions) {
                    console.log(action);
                    if ("enable" == action) {
                        for(let element in item) {
                            if ("object" != typeof item[element]) continue;
                            if ("ref" == element) continue;
                            // console.log(element);
                            // alert(JSON.stringify(item[element]));
                            item[element].hidden = false;
                            item[element].disabled = false;
                            touchedIndices.push(item.myIndex);
                        }
                    }
                    // ["disable", "enable", "hide", "use_mine_value_in_select", "set_my_value"]
                }
            }
        }
        let prevState = {... me.state};
        for(let touchedIndex of touchedIndices) {
            prevState.authorization_window_items[touchedIndex].interactionCounter += 1;
        }

        me.setState(prevState);
    }

    render() {
        let me = this;
        return <div className={"authorization-window"}>
            { me.props.window_data.show_logo &&
                <div style={{margin: "auto", display: "flex", flexDirection: "row", justifyContent: "center", marginTop: "10vh"}}>
                    <Logo style={{height: "auto", width: "30vw"}} />
                </div>
            }
            { me.props.window_data.title &&
                <h1>{me.props.window_data.title}</h1>
            }
            <div className={"content"} >
                { me.state.authorization_window_items.map(
                        (e, i) => {
                            return <AuthorizationWindowItem showToast={me.props.showToast} ref={e.ref} key={`${i}-${e.interactionCounter}`} handleInteraction={me.handleInteraction} callback={me.callback} data={e} />
                        }
                    )
                }
            </div>
        </div>
    }
}