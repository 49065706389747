import React from "react";
import Button from "../technical/button/Button";
import HtmlViewContainer from "../helpers/HtmlViewContainer";
import FieldInput from "../helpers/FieldInput";
import Accordeon from "./Accordeon";
import Style from 'style-it';
import MenuItemDialog from "./MenuItemDialog";
import QrZone from "./QrZone";

export default class MenuItem extends React.Component {
  constructor(props) {
    super(props);
    this.typeName = "MenuItem";

    this.htmlRef = React.createRef();
    this.buttonRef = React.createRef();
    this.fieldRef = React.createRef();
    this.accordeonRef = React.createRef();
    this.dialogRef = React.createRef();

    this.getValue = this.getValue.bind(this);
    this.openDialog = this.openDialog.bind(this);
  }

  getValue() {
    if (this.fieldRef.current) return this.fieldRef.current.getValue();
    return null;
  }

  openDialog() {
    // alert("Helllo from item");
    if (this.dialogRef.current) return this.dialogRef.current.open();
  }

  render() {
    let me = this;

    return (
      <Style key={`action-item-${me.props.data.id}`}>
        {me.props.data.custom_css}
        <div>
          {
            me.props.data.html &&
              <HtmlViewContainer ref={me.htmlRef} callback={me.props.callback} data={me.props.data.html} />
          }
          {
            me.props.data.button &&
              <Button ref={me.buttonRef} callback={me.props.callback} data={me.props.data.button} />
          }
          {
            me.props.data.field_input &&
              <FieldInput ref={me.fieldRef} handleInteraction={me.props.handleInteraction} callback={me.props.callback} data={me.props.data.field_input} />
          }
          {
            me.props.data.accordeon &&
              <Accordeon ref={me.accordeonRef} index={me.props.index} amount_of_items={me.props.amount_of_items} handleInteraction={me.props.handleInteraction} callback={me.props.callback} data={me.props.data.accordeon} />
          }
          {
            me.props.data.dialog &&
              <MenuItemDialog ref={me.dialogRef}  index={me.props.index}  callback={me.props.callback} data={me.props.data.dialog} />
          }
          {
            me.props.data.qr &&
              <QrZone ref={me.qrRef} index={me.props.index} callback={me.props.callback} data={me.props.data.dialog} />
          }
        </div>
      </Style>
    )
  }
}