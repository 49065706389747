export default class User {
    constructor(_userData) {
        let _inputData = _userData;
        if (!_userData) {
            _inputData = {};
        }
        this.last_name = _userData.last_name;
        this.middle_name = _userData.middle_name;
        this.first_name = _userData.first_name;
        this.email = _userData.email;
        this.cell = _userData.cell;
        this.token = _userData.token;

        this.describe = this.describe.bind(this);
        this.headerName = this.headerName.bind(this);
    }

    describe() {
        alert(JSON.stringify({
            last_name: this.last_name,
            first_name: this.first_name,
            middle_name: this.middle_name,
            email: this.email,
            cell: this.cell,
            token: this.token
        }));
    }

    headerName() {
        return `${this.last_name} ${this.first_name} ${this.middle_name }`;
    }


}