import React from "react";
import logo from "../logo.svg";
import "./loader.scss";
import Logo from "../header/Logo";

export default class Loader extends React.Component {
  constructor(_props) {
    super(_props);
    this.typeName = "Loader";
  }
  render() {
    let me = this;
    if (!me.props.loading) {
      return <></>;
    }

    return (<div className={"loader"}>
      <Logo style={{height: "auto", width: "40vw"}} />
    </div>);
  }
}