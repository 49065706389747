import React from "react";

export default class QrZone extends React.Component {
  constructor(props) {
    super(props);
    this.state = {

    };
    this.canvasRef = React.createRef();
  }
  render() {
    let me = this;
    return <div>
      <canvas ref={me.canvasRef} />
    </div>
  }
}