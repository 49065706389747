import React from "react";
import ReactHtmlParser from "html-react-parser";

export default class Logo extends React.Component {
  render() {
    let me = this;
    return (<>
      { me.props.use_alternative_logo &&
        <svg style={me.props.style}  xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 440 355" >
          <path className="st0" fill="white"
                d="M59.6,105.8H28.8v7.9h23.8c0,8.9-0.5,14.8-3,14.8c-2,0-20.9,0-20.9,0v8.4h30.8v16.8H6V89h53.6V105.8z"/>
                  <path className="st0" fill="white" d="M119.2,164v-9.9H85.5v9.9H65.1v-27.6c0,0,1.5,0,4.5-1c5.5-2,8.4-7.9,10.4-15.8c2.5-10.9,3.5-22.2,3.5-30.6h48.2
            v47.4h7.4V164H119.2z M100.9,106.3c-0.5,12.8-3.5,23.7-7.4,30.1h15.9v-30.1H100.9z"/>
                  <path className="st0" fill="white" d="M194.2,154.1V127v-5.9c0,0-14.4,19.2-17.4,24.2c-2.5,3.9-6,9.4-11.9,9.4c-7.9,0-16.4,0-16.4,0V90h21.4v28.6
            l-0.5,10.4c0,0,19.4-27.6,23.3-34c2.5-4.4,7-4.4,9.4-4.4c1.5,0,12.9,0,12.9,0v64.6h-20.9"/>
                  <path className="st0" fill="white" d="M249.3,111.7h20.4V89.5h22.8v64.6h-22.8V130h-19.9l-0.5,24.2h-22.8V89.5h22.8V111.7z"/>
                  <path className="st0" fill="white"
                        d="M374,154.1h-24.3l-5-13.3h-19.4l-4,13.3h-24.8l23.8-64.6h29.8L374,154.1z M328.3,127h13.4l-7-23.2L328.3,127z"
                  />
                  <path className="st0" fill="white" d="M413.2,154.1v-24.7c-3,0-6.5,0.5-7.5,3.5c-1,2-5,12.3-6.5,16.3c-2,4.4-3,5.4-5.5,5.4c-4.5,0-21.4,0-21.4,0
            l6-14.3c4-8.4,6.5-10.9,8.9-13.3c2.5-2.5,7-3.9,7-3.9c-2,0-7-1-10.9-3.5c-5-3.5-6.5-7.4-6.5-12.3c0-3.5,0.5-7.4,5.5-11.8
            c3.5-3,8.4-5.4,17.4-5.4h35.3v64.6h-21.9V154.1z M407.3,102.8c-2,0-4.5,0-6.5,1.5c-1.5,1-2.5,2.5-2.5,4.9c0,2.5,1,3.9,2,4.9
            c2,1.5,5,2,7,2h6v-12.8h-6V102.8z"/>
                  <path className="st0" fill="white" d="M338.7,249.3v-30.1v-6.9c0,0-15.4,22.2-18.9,27.1c-5,7.9-7.4,9.9-13.9,9.9c-8.9,0-16.9,0-16.9,0v-70.1h22.8
            v29.1l-0.5,10.9c0,0,20.9-28.1,25.3-35c3-4.4,7.4-4.9,10.4-4.9c1.5,0,14.4,0,14.4,0v70.1H338.7z"/>
                  <path className="st0" fill="white" d="M411.2,249.4v-26.6c-3.5,0-7,0.5-8.4,3.9c-1,2-5.5,13.3-7,17.3c-2,4.9-3,5.4-6,5.4c-5,0-22.8,0-22.8,0l6.5-15.3
            c4.5-9.4,7-11.8,9.4-14.3c2.5-2.5,7.4-4.4,7.4-4.4c-2,0-7.4-1-11.9-3.9c-5.5-3.5-7-8.4-7-13.3c0-3.5,1-8.4,6-12.8
            c3.5-3,9.4-5.4,18.9-5.4h38.2v70.1h-23.3V249.4z M404.8,194.1c-2,0-5,0.5-7,1.5c-1.5,1-2.5,3-2.5,5.4c0,2.5,1,3.9,2.5,4.9
            c2,1.5,5.5,2,7.4,2h6.5v-13.8H404.8z"/>
                  <path className="st0" fill="white" d="M34.8,179.3c7,0,18.9,0,25.8,5.9c4,3.5,7.9,9.4,7.9,19.2c0,14.8-10.4,24.7-24.8,24.7H30.8v19.7H6v-70.1h28.8
            V179.3z M30.3,212.8h6.5c7,0,8.4-5.9,8.4-8.9c0-3-1.5-8.4-8.4-8.4h-6.5V212.8z"/>
                  <path className="st0" fill="white" d="M137.6,189.2c6,6.4,8.9,15.8,9.4,24.7c0.5,17.8-9.9,36.5-37.7,36.5c-6.5,0-16.4-1-24.8-8.9
            C77,234.5,73,224.7,73,214.3c-0.5-21.7,14.4-36.5,37.2-36.5C120.7,177.8,130.7,181.3,137.6,189.2z M109.8,196.6
            c-10.4,0-11.4,11.3-11.4,17.8c0,6.4,1.5,17.3,11.9,17.3c10.4,0,11.4-10.9,11.4-16.8C121.2,202.5,115.8,196.6,109.8,196.6z"/>
                  <path className="st0" fill="white" d="M212.6,199.5c-3-0.5-8.9-1.5-15.4-1.5c-11.9,0-18.4,6.9-18.4,16.3c0,8.9,4.5,15.8,18.4,15.8
            c7,0,12.9-0.5,15.4-1l2.5,19.2c-11.9,2-21.9,2-23.3,2c-23.3,0-39.7-13.8-39.7-36.5c0-22.2,14.4-35.5,40.2-35.5
            c9.4,0,19.4,1.5,23.8,3L212.6,199.5z"/>
                  <path className="st0" fill="white" d="M278.2,199.5c-3-0.5-8.9-1.5-15.4-1.5c-11.9,0-18.4,6.9-18.4,16.3c0,8.9,4.5,15.8,18.4,15.8
            c7,0,12.9-0.5,15.4-1l2.5,19.2c-11.9,2-21.9,2-23.3,2c-23.3,0-39.7-13.8-39.7-36.5c0-22.2,14.4-35.5,40.2-35.5
            c9.4,0,19.4,1.5,23.8,3L278.2,199.5z"/>
        </svg>
      }

      { !me.props.use_alternative_logo &&
        <svg style={me.props.style} viewBox="0 0 189 174" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M20.2685 147.026L19.4696 152.657H13.3063V173.544H6.42013V152.657H0.256836V147.026H20.2685Z" fill="white"/>
          <path d="M41.5854 173.544H34.2046L32.835 168.445H26.5576L25.1118 173.544H17.9594L26.4815 147.026H33.5578L41.5854 173.544ZM31.4654 163.195L30.324 158.364C30.0704 157.273 29.9055 156.094 29.8294 154.825C29.728 155.84 29.487 157.184 29.1066 158.858L28.0413 163.195H31.4654Z" fill="white"/>
          <path d="M60.3942 147.026L59.5952 152.657H53.4319V173.544H46.5458V152.657H40.3825V147.026H60.3942Z" fill="white"/>
          <path d="M81.711 173.544H74.3303L72.9606 168.445H66.6832L65.2375 173.544H58.085L66.6071 147.026H73.6835L81.711 173.544ZM71.591 163.195L70.4497 158.364C70.196 157.273 70.0312 156.094 69.9551 154.825C69.8536 155.84 69.6127 157.184 69.2322 158.858L68.167 163.195H71.591Z" fill="white"/>
          <path d="M103.261 155.282C103.261 156.398 103.108 157.425 102.804 158.364C102.525 159.302 102.03 160.228 101.32 161.141C100.635 162.054 99.5702 162.777 98.1245 163.309C96.6787 163.842 94.9287 164.108 92.8742 164.108H90.7437V173.544H83.9337V147.026H92.5699C96.07 147.026 98.7205 147.673 100.521 148.966C102.347 150.26 103.261 152.365 103.261 155.282ZM95.8418 155.244C95.8418 154.204 95.6135 153.506 95.157 153.151C94.7004 152.796 94.0156 152.619 93.1025 152.619H90.7437V158.706H92.7221C93.8634 158.706 94.6624 158.478 95.1189 158.021C95.6008 157.539 95.8418 156.613 95.8418 155.244Z" fill="white"/>
          <path d="M124.872 171.299C122.945 173.1 120.193 174 116.616 174C113.066 174 110.174 172.783 107.942 170.348C105.913 168.09 104.899 164.907 104.899 160.798C104.899 157.577 105.507 154.838 106.725 152.581C108.906 148.573 112.482 146.57 117.453 146.57C118.823 146.57 120.142 146.747 121.41 147.102C122.678 147.457 123.617 147.927 124.225 148.51L121.334 153.532C120.117 152.593 118.798 152.124 117.377 152.124C116.058 152.124 115.069 152.467 114.41 153.151C113.75 153.811 113.294 154.559 113.04 155.396C112.812 156.233 112.698 157.425 112.698 158.972C112.698 161.306 112.774 163.03 112.926 164.146C113.104 165.262 113.459 166.125 113.991 166.733C114.93 167.849 116.058 168.407 117.377 168.407C119.127 168.407 120.649 167.824 121.943 166.657L124.872 171.299Z" fill="white"/>
          <path d="M145.661 147.026L144.862 152.657H138.699V173.544H131.813V152.657H125.649V147.026H145.661Z" fill="white"/>
          <path d="M166.978 173.544H159.597L158.228 168.445H151.95L150.504 173.544H143.352L151.874 147.026H158.95L166.978 173.544ZM156.858 163.195L155.717 158.364C155.463 157.273 155.298 156.094 155.222 154.825C155.121 155.84 154.88 157.184 154.499 158.858L153.434 163.195H156.858Z" fill="white"/>
          <path d="M188.261 173.544H181.451V162.396H176.011V173.544H169.201V147.026H176.011V156.766H181.451V147.026H188.261V173.544Z" fill="white"/>
          <path d="M76.0722 126.848H0V0H73.7063L71.3404 26.7527H32.5764V48.0456H64.2428V73.8883H32.5764V99.731H76.0722V126.848Z" fill="white"/>
          <path d="M187.598 39.492C187.598 44.8304 186.87 49.7442 185.415 54.2333C184.08 58.7224 181.714 63.1508 178.317 67.5186C175.041 71.8864 169.945 75.3442 163.03 77.8921C156.114 80.44 147.742 81.7139 137.915 81.7139H127.723V126.848H95.1471V0H136.459C153.202 0 165.881 3.09384 174.495 9.28153C183.231 15.4692 187.598 25.5394 187.598 39.492ZM152.11 39.31C152.11 34.3356 151.018 30.9991 148.834 29.3005C146.651 27.602 143.375 26.7527 139.007 26.7527H127.723V55.8712H137.187C142.647 55.8712 146.469 54.7793 148.652 52.5954C150.958 50.2901 152.11 45.8617 152.11 39.31Z" fill="white"/>
        </svg>
      }
    </>);
  }
}