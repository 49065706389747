import React from 'react';
import "./lower-menu.scss";

export default class LowerMenuButton extends React.Component{

    constructor(props) {
        super(props);
        this.typeName = "LowerMenuButton";
    }

    render() {
        let color = "var(--white_alpha64)";

        if (this.props.active) {
            color = "var(--white)";
        }

        return (<div onClick={this.props.onClick} className="lower-menu-button" style={this.props.opacity ? {opacity: 0.5, pointerEvents: 'none'} : {}}>
            <i className={this.props.fontAwesomeClass} style={{color: color}}/>
            <p style={{marginTop: "4px", color: color}}>{this.props.label}</p>
        </div>);
    }
}