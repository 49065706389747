import User from "./User.mjs";

export default class CacheStorageForData {

    static fakeUserLogin() {
        return {
            action: {
                sign_in: {
                    user: {
                        id: 214,
                        last_name: "Васильев",
                        first_name: "Иван",
                        middle_name: "Петрович",
                        token: "1232423423423",
                        email: "someone@somewhere.com",
                        cell: "+7(965)593-68-67"
                    }
                }
            }
        }
    }


    constructor(_appContainer) {
        this.signIn = this.signIn.bind(this);

        this.appContainer = _appContainer;
        this.currentUserData = localStorage.getItem("user_data");
        this.newsCarouselData = localStorage.getItem("news_carousel_data");
        this.burgerMenuData = localStorage.getItem("burger_menu_data");
        this.menuData = localStorage.getItem("main_menu_date");
        this.lowerMenuData = localStorage.getItem("lower_menu_data");
        this.loaderWindowData = localStorage.getItem("loader_window_data");
        this.authorizationWindowData = localStorage.getItem("authorization_window_data");
        this.onboardingData = localStorage.getItem("onboarding_data");
        this.messagesData = localStorage.getItem("messages_data");

        this.newsCarouselData = {
            title: "Актуальное",
            newsItems: CacheStorageForData.fakeNews()
        };

        this.lowerMenuData = CacheStorageForData.fakeLowerMenu();

        this.authorizationWindowData = CacheStorageForData.fakeAuthData();
        this.user = null;
        this.menuData = CacheStorageForData.fakeMenu();
        if (this.currentUserData) {
            this.user = new User(JSON.parse(this.currentUserData));
        }
        // this.user.describe();
    }

    signIn(user, app) {
        this.user = new User(user);
        try {
            if (user.token) {
                app.updateCounter("authorization");
                app.updateCounter("header");
                app.updateCounter("work_zone");
                app.updateCounter("news_carousel");
                app.updateCounter("lower_menu");
                localStorage.setItem("user_data", JSON.stringify(user));
                setTimeout(() => {
                    app.showToast(`Вы вошли как ${user.last_name} ${user.first_name}`, "success");
                }, 1000);
            }
        } catch (e) {
        }
    }

    updateUserData(user, app) {
        this.user = new User(user);
        app.updateCounter("header");
        localStorage.setItem("user_data", JSON.stringify(user));
        setTimeout(() => {
            app.showToast(`Данные обновлены`, "success");
        }, 1000);
    }

    startDemo() {
        // appContainer
    }

    static fakeMenu() {
        return {
            "https://main_content12323dfdd.url/level_2": {
                hide_back_button: false,
                hide_news: true,
                menu_items: [
                    {
                      html: {
                          content: `
                          <iframe width="100%" height="315" src="https://www.youtube.com/embed/3mTV1TOblbA" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                          `
                      }
                    },
                    {
                        html: {
                            element_id: "good_start",
                            css: ".test-html { background: #f00; margin-bottom: 8px;}",
                            content: `
                              <div style="display: flex;" class="test-html">
                                <p>1</p>
                                <p>2</p>
                                <p>${Math.random()}</p>
                               </div>
                            `,
                        },
                        button: {
                            disabled: false,
                            field_name: "enter_button",
                            caption: `Конференция ТРО - ${Math.random()}`,
                            button_type: "primary",
                            show_rounded_arrow: true,
                            fa_icon: "fal fa-home fa-2x",
                            alignLeft: true,
                            align_left: true,
                            action: {
                                get_menu: "https://main_content12323dfdd.url/level_2",
                                from_beginning: false,
                                payload: {}
                            }
                        }
                    }
                ]
            },
            "https://main_content12323dfdd.url/fwewefewfwef": {
                hide_back_button: false,
                menu_items: [
                    {
                        button: {
                            disabled: false,
                            field_name: "enter_button",
                            fa_icon: "fal fa-home fa-2x",
                            caption: `Конференция ТРО - ${Math.random()}`,
                            button_type: "primary",
                            show_rounded_arrow: true,
                            action: {
                                get_menu: "https://main_content12323dfdd.url/level_2",
                                from_beginning: false,
                                payload: {}
                            }
                        }
                    },
                    {
                        button: {
                            disabled: false,
                            field_name: "enter_button",
                            caption: "Конференция МОП",
                            button_type: "primary",
                            show_rounded_arrow: true,
                            action: {
                                context: "action_window",
                                sendForm: true,
                                sendUrl: "https://wefwee2332.com/check_code"
                            }
                        }
                    },
                    {
                        button: {
                            disabled: false,
                            field_name: "enter_button",
                            caption: "Президиум РПС",
                            button_type: "primary",
                            show_rounded_arrow: true,
                            action: {
                                context: "action_window",
                                sendForm: true,
                                sendUrl: "https://wefwee2332.com/check_code"
                            }
                        }
                    },
                    {
                        button: {
                            disabled: false,
                            field_name: "enter_button",
                            caption: "Заседание РПС",
                            button_type: "primary",
                            show_rounded_arrow: true,
                            action: {
                                context: "action_window",
                                sendForm: true,
                                sendUrl: "https://wefwee2332.com/check_code"
                            }
                        }
                    },
                    {
                        button: {
                            disabled: false,
                            field_name: "enter_button",
                            caption: "День голосования ",
                            button_type: "primary",
                            show_rounded_arrow: true,
                            action: {
                                get_menu: "https://wefwee2332.com/get_another_menu",
                                from_beginning: false,
                                action: {}
                            }
                        }
                    }
                ]
            }
        };
    }

    static fakeLowerMenu() {
        return {
            title: "LowerMenu",
            menu: [
                {
                    title: "Домой",
                    font_awesome_class: "fas fa-home fa-2x",
                    action: {
                        get_menu: "https://new.tatarstaner.space/app/main_menu",
                        from_beginning: true,
                        action: {}
                    }
                },
                {
                    title: "Сообщения",
                    font_awesome_class: "fas fa-envelope-open fa-2x",
                    action: {
                        get_menu: "https://main_content12323dfdd.url/fwewefewfwef",
                        from_beginning: true,
                        action: {}
                    }
                },
                {
                    title: "Обучение",
                    font_awesome_class: "fas fa-user-graduate fa-2x"
                },
                {
                    title: "Настройки",
                    font_awesome_class: "fas fa-sliders-h fa-2x"
                }
            ]
        };
    }

    static fakeNews() {
        return [
            {
                title: "Система Избиратель-депутат",
                call_to_action: "Показать",
                content: "",
                pic_url: "https://tatarstaner.space/uploads/m0jsgn54wjbc2o6%D0%A1%D0%BD%D0%B8%D0%BC%D0%BE%D0%BA%20%D1%8D%D0%BA%D1%80%D0%B0%D0%BD%D0%B0%202020-10-16%20%D0%B2%2010.26.02.png"
            },
            {
                title: "Обратиться к Председателю Партии",
                call_to_action: "Показать",
                pic_url: "https://tatarstaner.space/uploads/m0jsgn54wjbc2o6%D0%A1%D0%BD%D0%B8%D0%BC%D0%BE%D0%BA%20%D1%8D%D0%BA%D1%80%D0%B0%D0%BD%D0%B0%202020-10-16%20%D0%B2%2010.26.02.png"
            },
            {
                title: "Партия в лицах",
                call_to_action: "Показать",
                pic_url: "https://tatarstaner.space/uploads/m0jsgn54wjbc2o6%D0%A1%D0%BD%D0%B8%D0%BC%D0%BE%D0%BA%20%D1%8D%D0%BA%D1%80%D0%B0%D0%BD%D0%B0%202020-10-16%20%D0%B2%2010.26.02.png"
            },
            {
                title: "Система Избиратель-депутат",
                call_to_action: "Показать",
                pic_url: "https://tatarstaner.space/uploads/m0jsgn54wjbc2o6%D0%A1%D0%BD%D0%B8%D0%BC%D0%BE%D0%BA%20%D1%8D%D0%BA%D1%80%D0%B0%D0%BD%D0%B0%202020-10-16%20%D0%B2%2010.26.02.png"
            },
            {
                title: "Обратиться к Председателю Партии",
                call_to_action: "Показать",
                pic_url: "https://tatarstaner.space/uploads/m0jsgn54wjbc2o6%D0%A1%D0%BD%D0%B8%D0%BC%D0%BE%D0%BA%20%D1%8D%D0%BA%D1%80%D0%B0%D0%BD%D0%B0%202020-10-16%20%D0%B2%2010.26.02.png"
            },
            {
                title: "Партия в лицах",
                call_to_action: "Показать",
                pic_url: "https://tatarstaner.space/uploads/m0jsgn54wjbc2o6%D0%A1%D0%BD%D0%B8%D0%BC%D0%BE%D0%BA%20%D1%8D%D0%BA%D1%80%D0%B0%D0%BD%D0%B0%202020-10-16%20%D0%B2%2010.26.02.png"
            },
            {
                title: "Система Избиратель-депутат",
                call_to_action: "Показать",
                pic_url: "https://tatarstaner.space/uploads/m0jsgn54wjbc2o6%D0%A1%D0%BD%D0%B8%D0%BC%D0%BE%D0%BA%20%D1%8D%D0%BA%D1%80%D0%B0%D0%BD%D0%B0%202020-10-16%20%D0%B2%2010.26.02.png"
            },
            {
                title: "Обратиться к Председателю Партии",
                call_to_action: "Показать",
                pic_url: "https://tatarstaner.space/uploads/m0jsgn54wjbc2o6%D0%A1%D0%BD%D0%B8%D0%BC%D0%BE%D0%BA%20%D1%8D%D0%BA%D1%80%D0%B0%D0%BD%D0%B0%202020-10-16%20%D0%B2%2010.26.02.png"
            },
            {
                title: "Партия в лицах",
                call_to_action: "Показать",
                pic_url: "https://tatarstaner.space/uploads/m0jsgn54wjbc2o6%D0%A1%D0%BD%D0%B8%D0%BC%D0%BE%D0%BA%20%D1%8D%D0%BA%D1%80%D0%B0%D0%BD%D0%B0%202020-10-16%20%D0%B2%2010.26.02.png"
            },
            {
                title: "Система Избиратель-депутат",
                call_to_action: "Показать",
                pic_url: "https://tatarstaner.space/uploads/m0jsgn54wjbc2o6%D0%A1%D0%BD%D0%B8%D0%BC%D0%BE%D0%BA%20%D1%8D%D0%BA%D1%80%D0%B0%D0%BD%D0%B0%202020-10-16%20%D0%B2%2010.26.02.png"
            },
            {
                title: "Обратиться к Председателю Партии",
                call_to_action: "Показать",
                pic_url: "https://tatarstaner.space/uploads/m0jsgn54wjbc2o6%D0%A1%D0%BD%D0%B8%D0%BC%D0%BE%D0%BA%20%D1%8D%D0%BA%D1%80%D0%B0%D0%BD%D0%B0%202020-10-16%20%D0%B2%2010.26.02.png"
            },
            {
                title: "Партия в лицах",
                call_to_action: "Показать",
                pic_url: "https://tatarstaner.space/uploads/m0jsgn54wjbc2o6%D0%A1%D0%BD%D0%B8%D0%BC%D0%BE%D0%BA%20%D1%8D%D0%BA%D1%80%D0%B0%D0%BD%D0%B0%202020-10-16%20%D0%B2%2010.26.02.png"
            },
            {
                title: "Система Избиратель-депутат",
                call_to_action: "Показать",
                pic_url: "https://tatarstaner.space/uploads/m0jsgn54wjbc2o6%D0%A1%D0%BD%D0%B8%D0%BC%D0%BE%D0%BA%20%D1%8D%D0%BA%D1%80%D0%B0%D0%BD%D0%B0%202020-10-16%20%D0%B2%2010.26.02.png"
            },
            {
                title: "Обратиться к Председателю Партии",
                call_to_action: "Показать",
                pic_url: "https://tatarstaner.space/uploads/m0jsgn54wjbc2o6%D0%A1%D0%BD%D0%B8%D0%BC%D0%BE%D0%BA%20%D1%8D%D0%BA%D1%80%D0%B0%D0%BD%D0%B0%202020-10-16%20%D0%B2%2010.26.02.png"
            },
            {
                title: "Партия в лицах",
                call_to_action: "Показать",
                pic_url: "https://tatarstaner.space/uploads/m0jsgn54wjbc2o6%D0%A1%D0%BD%D0%B8%D0%BC%D0%BE%D0%BA%20%D1%8D%D0%BA%D1%80%D0%B0%D0%BD%D0%B0%202020-10-16%20%D0%B2%2010.26.02.png"
            },
            {
                title: "Система Избиратель-депутат",
                call_to_action: "Показать",
                pic_url: "https://tatarstaner.space/uploads/m0jsgn54wjbc2o6%D0%A1%D0%BD%D0%B8%D0%BC%D0%BE%D0%BA%20%D1%8D%D0%BA%D1%80%D0%B0%D0%BD%D0%B0%202020-10-16%20%D0%B2%2010.26.02.png"
            },
            {
                title: "Обратиться к Председателю Партии",
                call_to_action: "Показать",
                pic_url: "https://tatarstaner.space/uploads/m0jsgn54wjbc2o6%D0%A1%D0%BD%D0%B8%D0%BC%D0%BE%D0%BA%20%D1%8D%D0%BA%D1%80%D0%B0%D0%BD%D0%B0%202020-10-16%20%D0%B2%2010.26.02.png"
            },
            {
                title: "Партия в лицах",
                call_to_action: "Показать",
                pic_url: "https://tatarstaner.space/uploads/m0jsgn54wjbc2o6%D0%A1%D0%BD%D0%B8%D0%BC%D0%BE%D0%BA%20%D1%8D%D0%BA%D1%80%D0%B0%D0%BD%D0%B0%202020-10-16%20%D0%B2%2010.26.02.png"
            }
        ];
    }

    static fakeAuthData() {
        return {
            "https://fdsfdfsd.asasddas/enter_using_cell": {
            id: 2,
              title: "ВХОД В СИСТЕМУ",
              has_demo_entry: true,
              scrollable: false,
              show_logo: true,
              authorization_window_items: [
                {
                    field_input: {
                        placeholder: "Номер телефона",
                        mask: "+7(000)-000-00-00",
                        field_name: "cell",
                        field_type:"tel",
                        trigger_feedback: true,
                        feedback_url: "https://12321wdd.com/check_pin",
                        interaction: {
                            "pin": ["enable"]
                        },
                        // depends_on:string = "[]"
                        // default_value:string = ""
                        order_index: 0,
                        get_url: "https://fdfsdsdfsdf/check_sms",
                    }
                },
                {
                    field_input: {
                        placeholder: "Код",
                        mask: "0-0-0-0",
                        field_name: "pin",
                        field_type:"tel",
                        needs_feedback: true,
                        hidden: true,
                        // depends_on:string = "[]"
                        // default_value:string = ""
                        order_index: 0,
                        get_url: "https://fdfsdsdfsdf/check_sms",
                        interaction: {
                            "enter_button": ["enable"]
                        }
                    }
                },
                {
                    button: {
                        disabled: true,
                        field_name: "enter_button",
                        caption: "Войти",
                        button_type: "primary",
                        show_arrow: true,
                        action: {
                            context: "action_window",
                            sendForm: true,
                            sendUrl: "https://wefwee2332.com/check_code"
                        }
                    }
                }
            ]
        },
            "https://fdsfdfsd.asasddas/auth_start.json": {
            id: 3,
              title: "ВХОД В СИСТЕМУ",
              has_demo_entry: true,
              scrollable: false,
              show_logo: true,
              authorization_window_items: [
                {
                    button: {
                        caption: "Вход через систему\n'ВСЯ РОССИЯ'",
                        button_type: "primary",
                        show_arrow: true,

                    }
                },
                {
                    button: {
                        caption: "Вход по номеру телефона",
                        button_type: "secondary",
                        show_arrow: true,
                        action: {
                            get_object_from_url: "https://fdsfdfsd.asasddas/enter_using_cell"
                        }
                    }
                }
            ]
        }
        };
    }
}