import React from "react";
import "./app-content.scss";
import $ from "jquery";
import CacheStorageForData from "../technical/CacheStorageForData.mjs";
import MenuItem from "./MenuItem";
import ActionParser from "../technical/ActionParser.mjs";
import Button from "../technical/button/Button";

export default class WorkZone extends React.Component {
  constructor(_props) {
    super(_props);
    this.state = {
      menu: null,
      menus_stack: [],
      menuGetInterval: null,
      prevValues: {
        hideMenu: null
      }
    };

    this.typeName = "WorkZone";
    this.getMenu = this.getMenu.bind(this);
    this.downloadMenu = this.downloadMenu.bind(this);
    this.installMenu = this.installMenu.bind(this);
    this.callback = this.callback.bind(this);
    this.resetStack = this.resetStack.bind(this);
    this.handleInteraction = this.handleInteraction.bind(this);
    this.serializeForm = this.serializeForm.bind(this);
    this.openDialog = this.openDialog.bind(this);
  }

  callback(action) {
    let me = this;
    // alert(JSON.stringify(action));
    ActionParser.parseAction(action, me);
  }

  componentDidMount() {
    let url = this.props.mainContentUrl;
    this.getMenu(url);
  }

  getMenu(menuUrl, fromBeginning, payload) {
    let me = this;
    if (!me.props.user) return;
    // TODO: Скачка новаого менбю
      me.downloadMenu(menuUrl, payload).then( (menu) => {
        me.installMenu(menu, fromBeginning);
      }).catch((err) => {
        me.props.showToast("Проблемы со связью", "error");
        //TODO: убрать фейк
        // me.installMenu(me.props.cacheStorageForData.menuData[menuUrl], fromBeginning);
      });
      return;

    // if (me.state.menu.updateInterval) {
    //   me.downloadMenu(me.state.menu.url, payload).then((menu) => {
    //     me.installMenu(menu, fromBeginning);
    //   }).catch((err) => {
    //     me.props.showToast("Проблемы со связью", "error");
    //     //TODO: убрать фейк
    //     me.installMenu(CacheStorageForData.fakeRootMenu(), fromBeginning);
    //   });
    // }
    // this.getMenuWithParsel(menu_id, fromBeginning, {});
  }

  goBack() {
    let me = this;
    let prevState = {... me.state};
    prevState.menus_stack.splice(prevState.menus_stack.length - 1,1);
    prevState.menu = prevState.menus_stack[prevState.menus_stack.length - 1];
    let menu = prevState.menu;
    me.setState(prevState, () => {
      // if (prevState.prevValues.hideMenu != !!menu.hide_news) {
        let prevStateSecondLevel = {... this.state};
        prevStateSecondLevel.prevValues.hideMenu = !!menu.hide_news;
        // alert(!!menu.hide_news);
        me.props.setNewsInVisibility(!!menu.hide_news).then(() => {
          // me.setState(prevStateSecondLevel, () => {
          // });
        });
      // }
    });
  }

  resetStack() {
    let me = this;
    let prevState = {... me.state};
    let prevMenu = prevState.menus_stack[0];
    prevState.menus_stack = [prevState.menus_stack[0]];
    prevState.menu = prevMenu;
    me.setState(prevState);
  }

  installMenu(menu, fromBeginning) {
    let me = this;
    if (!menu) {
      me.props.showToast("Возникла ошибка, пожалуйста, попробуйте позднее", "error");
      return;
    }
    if (!menu.menu_items) {
      me.props.showToast("Возникла ошибка: нет контента", "error");
      return;
    }
    let prevState = {... this.state};
    // alert(2);

    if (fromBeginning) {
      prevState.menus_stack = [menu];
    } else {
      if (menu.is_replacing) {
        prevState.menus_stack[prevState.menus_stack.length - 1] = menu;
      } else {
        prevState.menus_stack.push(menu);
      }
    }
    let processedMenuItems = menu.menu_items.map((x, myIndex) => {
      x.ref = React.createRef();
      x.interactionCounter = 0;
      x.myIndex = myIndex;
      return x;
    });
    menu.marker = JSON.stringify(menu);
    menu.menu_items = processedMenuItems;

    prevState.menu = menu;
    me.setState(prevState, () => {
      if (menu.update_my_local_data) {
        me.props.updateMyLocalData();
      }
      // alert(JSON.stringify(menu));
      // alert(menu.hide_news);
      // if (prevState.prevValues.hideMenu != !!menu.hide_news) {
      //   let prevStateSecondLevel = {... this.state};
      //   prevStateSecondLevel.prevValues.hideMenu = !!menu.hide_news;
      // alert(!!menu.hide_news);
        me.props.setNewsInVisibility(!!menu.hide_news).then(() => {
          // me.setState(prevStateSecondLevel, () => {
          // });
        })
      // }
    });
    // alert(234234);
  }

  async downloadMenu(menuUrl, payload) {
    let me = this;
    let data = {
      token: me.props.user.token,
      payload: payload
    };

    if (me.state.menu) {
      if (!me.state.menu.hide_loader) me.props.showLoader(true);
    }

    return new Promise((resolve, reject) => {
      $.ajax({
        url: menuUrl,
        data: data,
        method: "post",
        dataType: 'json',
        success: (gotMenu) => {
          me.props.showLoader(false).then( () => {
            resolve(gotMenu);
          });
        },
        error: (gotError) => {
          me.props.showLoader(false).then( () => {
            reject(false);
          });
        }
      })
    });
  }

  serializeForm() {
    let me = this;
    let res = {token: me.props.user.token};
    for(let item of me.state.menu.menu_items) {
      let data = item.ref.current.getValue();
      if (data) res[data[0]] = data[1];
    }
    // alert(JSON.stringify(res));
    return res;
  }

  openDialog(dialogId) {
    // alert(dialogId);
    let me = this;
    for(let mItem of me.state.menu.menu_items) {
      if (mItem.dialog) {
        if (mItem.dialog.id == dialogId) {
          // alert(12312);
          mItem.ref.current.openDialog();
          return;
        }
      }
    }
  }

  handleInteraction(parsel) {
    let me = this;
    // alert(2131);
    let fields = Object.keys(parsel.interaction);
    // alert(`Handling interaction: ${JSON.stringify(parsel.interaction)}`);
    // alert(fields);
    let touchedIndices = [];
    let menu_items = me.state.menu.menu_items;
    // alert(menu_items.length);
    for(let field of fields) {
      let windowItems = menu_items.filter((x) => {
        for(let element in x) {
          // alert(x[element].field_name);
          if (field === x[element].field_name) {
            return true;
          }
        }
        return false;
      });
      let actions = parsel.interaction[field];
      // alert(authorizationWindowItems);
      for(let item of windowItems) {
        console.log(item);
        for(let action of actions) {
          console.log(action);
          if ("enable" == action) {
            for(let element in item) {
              if ("object" != typeof item[element]) continue;
              if ("ref" == element) continue;
              // console.log(element);
              // alert(JSON.stringify(item[element]));
              item[element].hidden = false;
              item[element].disabled = false;
              touchedIndices.push(item.myIndex);
            }
          }
          // ["disable", "enable", "hide", "use_mine_value_in_select", "set_my_value"]
        }
      }
    }

    let prevState = {... me.state};
    for(let touchedIndex of touchedIndices) {
      if (menu_items[touchedIndex].interactionCounter)
        menu_items[touchedIndex].interactionCounter = 1;
      else
        menu_items[touchedIndex].interactionCounter += 1;
    }
    prevState.menu.menu_items = menu_items;
    me.setState(prevState);
  }

  render() {
    let me = this;
    return <div className={"app-content"} key={`${me.state.menu ? me.state.menu.marker : 'empty'}`}>
      { (me.state.menus_stack.length > 1) &&
        <div style={{width: "calc(100% - 16px)"}}>
          {(!me.state.menu.hide_back_button) &&
            <Button callback={me.callback} data={{action: {go_back: true}, caption: "Назад", button_type: "back_button"}}/>
          }
        </div>
      }
      { me.state.menu &&
        <div className={"menu"}>
          { me.state.menu.menu_items.map((menuItem, i) => {
              return <MenuItem ref={menuItem.ref} index={i} amount_of_items={me.state.menu.menu_items.length} handleInteraction={me.handleInteraction} callback={me.callback} data={menuItem} key={`menu-item-${i}-${menuItem.interactionCounter}`}/>
            })
          }
        </div>
      }
    </div>
  }
}