import React from "react";
import Header from "./header/Header.jsx";
import CacheStorageForData from "./technical/CacheStorageForData.mjs";
import NewsCarousel from "./news/NewsCarousel";
import LowerMenu from "./lower_menu/LowerMenu";
import Authorization from "./authorization/Authorization";
import WorkZone from "./main_content/WorkZone";
import Loader from "./main_content/Loader";
import $ from "jquery";

const mainContentUrl = "https://new.tatarstaner.space/app/main_menu";

export default class App extends React.Component {

  constructor(props) {
      super(props);
      let appContainer = this;

      this.state = {
        cache_storage_for_data: new CacheStorageForData(appContainer),
        counters: {
          authorization: 0,
          header: 0,
          news_carousel: 0,
          work_zone: 0,
          lower_menu: 0
        },
        hideNews: false,
        loading: false
      };

      this.headerRef = React.createRef();
      this.newsRef = React.createRef();
      this.lowerMenuRef = React.createRef();
      this.authorizationRef = React.createRef();
      this.contentRef = React.createRef();
      this.loaderRef = React.createRef();

      this.signIn = this.signIn.bind(this);
      this.updateCounter = this.updateCounter.bind(this);
      this.showToast = this.showToast.bind(this);
      this.lowerMenuCallback = this.lowerMenuCallback.bind(this);
      this.setNewsInVisibility = this.setNewsInVisibility.bind(this);
      this.showLoader = this.showLoader.bind(this);
      this.updateMyLocalData = this.updateMyLocalData.bind(this);
  }

  componentDidMount() {
    let me = this;
    if (me.props.use_anonym_auth) {
      me.signIn({
        token: "anonym",
        last_name: "",
        first_name: "",
        middle_name: ""
      });
    }
  }

  updateMyLocalData() {
    let me = this;
    $.ajax({
      url: "https://new.tatarstaner.space/users/my_data",
      method: "post",
      dataType: "json",
      data: {
        token: me.state.cache_storage_for_data.user.token
      },
      success: (e) => {
        me.state.cache_storage_for_data.updateUserData(e, me);
      }
    })
  }

  async setNewsInVisibility(_invisibility) {
    let me = this;
    return new Promise((resolve, reject) => {
      // let prevState = {... me.state};
      // prevState.hideNews = _invisibility;
      // prevState.counters.news_carousel += 1;
      me.newsRef.current.setInVisibility(_invisibility).then(() => {
        resolve(true);
      });
      // me.setState(prevState,() => {
      //   me.updateCounter("news_carousel").then(() => {
      //     resolve(true);
      //   })
      // });
    })
    // alert(_invisibility);
  }

  async updateCounter(counterName) {
    let me = this;
    return new Promise(((resolve, reject) => {
      let prevState = {... me.state};
      prevState.counters[counterName] += 1;
      me.setState(prevState, () => {
        resolve(true);
      });
    }));
  }

  showToast(content, toastType) {
    this.headerRef.current.showToast(content, toastType);
  }

  signIn(user) {
    let thisApp = this;
    this.state.cache_storage_for_data.signIn(user, thisApp);
  }

  lowerMenuCallback(action) {
    this.contentRef.current.callback(action);
  }

  showLoader(showing) {
    let me = this;
    return new Promise((resolve, reject) => {
      let prevState = {... me.state};
      prevState.loading = showing;
      setTimeout(() => {
        me.setState(prevState, () => {
          setTimeout(() => {
            me.showLoader(false);
          }, 5000);
          resolve(true);
        });
      }, showing ? 0 : 1000 );
    });
  }

  render () {
    let me = this;
    let initUrl = mainContentUrl;
    if (me.props.start_menu_url) {
      initUrl = me.props.start_menu_url;
    }
    return (
      <div style={{position: "relative", minHeight: "100vh", paddingTop: "20px"}}>

        <Header
                disable_toasts={me.props.disable_toasts}
                logo_style={me.props.logo_style}
                hide_me={me.props.hide_header}
                key={`header-${me.state.counters.header}`}
                ref={me.headerRef}
                user={me.state.cache_storage_for_data.user}
                cacheStorageForData={me.state.cache_storage_for_data}
                callback={me.lowerMenuCallback}
                use_alternative_logo={me.props.use_alternative_logo}
        />

        <NewsCarousel
                hide_me={me.props.hide_news}
                counter={me.state.counters.news_carousel}
                hideNews={me.state.hideNews}
                key={`news-items-${me.state.counters.news_carousel}`}
                user={me.state.cache_storage_for_data.user}
                ref={me.newsRef} server_url={"https://new.tatarstaner.space/app/news_items.json"}
                cacheStorageForData={me.state.cache_storage_for_data}
        />

        <WorkZone cacheStorageForData={me.state.cache_storage_for_data}
                showToast={me.showToast}
                user={me.state.cache_storage_for_data.user}
                key={`work_zone-${me.state.counters.work_zone}`}
                mainContentUrl={initUrl}
                ref={me.contentRef}
                setNewsInVisibility={me.setNewsInVisibility}
                showLoader={me.showLoader}
                updateMyLocalData={me.updateMyLocalData}
        />

        <LowerMenu callback={me.lowerMenuCallback}
                   hide_me={me.props.hide_lower_menu}
                   key={`lower-menu-${me.state.counters.lower_menu}`}
                   user={me.state.cache_storage_for_data.user}
                   ref={me.lowerMenuRef}
                   server_url={"https://new.tatarstaner.space/app/lower_menu_items"}
                   cacheStorageForData={me.state.cache_storage_for_data}
        />

        <Authorization user={me.state.cache_storage_for_data.user}
                   showToast={me.showToast}
                   key={`auth-${me.state.counters.authorization}`}
                   signIn={me.signIn} ref={me.authorizationRef}
                   server_url={"https://new.tatarstaner.space/authorization/get_root_menu"}
                   cacheStorageForData={me.state.cache_storage_for_data}
        />

        <Loader key={`loader-${me.state.loading}`} loading={me.state.loading} ref={me.loaderRef}/>

      </div>
    );
  }
}