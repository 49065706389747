import React from "react";
import ReactHtmlParser from 'html-react-parser';
import convertNodeToElement from 'html-react-parser';

import $ from "jquery";
import Snackbar from '@material-ui/core/Snackbar';

export default class HtmlViewContainer extends React.Component {
  constructor(_props) {
    super(_props);
    this.state = {
    };
    this.typeName = "HtmlViewContainer";
    // alert(JSON.stringify(this.props.data));
    this.ref = React.createRef();
    this.parseAction = this.parseAction.bind(this);
  }

  async parseAction(command, event) {
    let me = this;
    event.stopPropagation();
    // alert(event.target.getAttribute("command"))
    try {
      let action = JSON.parse(event.target.getAttribute("command"));
      me.props.callback(action);
    } catch (e) {}
  }

  render() {
    let me = this;
    if (!me.props.data.content) return <></>;
    return (
        <div style={{position: "relative"}} ref={me.ref} id={me.props.data.element_id} >
          <div onClick={(ev) => {me.parseAction(me.props.data.action, ev)}}>
            {
              ReactHtmlParser (
                me.props.data.content
              )
            }
          </div>
        </div>
    );
    // return <h1 style={{color: "#000"}}>HTML</h1>;
  }
}