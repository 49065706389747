import $ from "jquery";
import CacheStorageForData from "./CacheStorageForData.mjs";

export default class ActionParser {
    static parseAction(action, target) {
        let thisClass = this;

        let targetType = target.constructor.name;
        targetType = target.typeName;
        // alert(JSON.stringify(action));
        if (action.src_href) {
            let target = !action.src_target ? "_system" :  action.src_target;
            try{
                window.inAppBrowser.open(action.src_href, target, "location=no");
            } catch(e) {
                // let loader = document.querySelector("#loader");
                // loader.style.display = 'flex';
                // setTimeout(() => {loader.style.display = 'none'}, 2000)
            }
            // window.open(e.src_href, target);
            return;
        }

        if (!!action.sign_out) {
            localStorage.removeItem("user_data");
            document.location.reload();
            return;
        }

        if ("Authorization" == targetType) {
            // alert(JSON.stringify(action))
            if (action.get_object_from_url) {
                // alert(action.get_object_from_url);
                target.getAuthorizationWindow(action.get_object_from_url);
            }
            // if ()
        }

        if ("AuthorizationWindow" == targetType) {
            if (action.sendForm) {
                let data = target.serializeParcel();
                $.ajax({
                    url: action.sendUrl,
                    method: "post",
                    data: data,
                    dataType: "json",
                    success: (callBackActionFromBackend) => {
                        thisClass.parseAction(callBackActionFromBackend.action, target);
                    },
                    error: (errorCallBackActionFromBackend) => {
                        //TODO: Убрать фейк ответ
                        let fakeAnswer = CacheStorageForData.fakeUserLogin();
                        thisClass.parseAction(fakeAnswer.action, target);
                    }
                });
            }

            if (action.sign_in) {
                target.signIn(action.sign_in.user);
            }
        }
        if ("WorkZone" == targetType) {
            if (action.open_dialog) {
                // alert(12312111);
                target.openDialog(action.open_dialog);
                return;
            }

            if (action.send_form) {
                $.ajax(
                  {
                      url: action.send_form,
                      method: "post",
                      data: {
                          content: target.serializeForm()
                      },
                      success: (e) => {
                          // alert("Отправлено");
                          target.installMenu(e, e.from_beginning);
                      }
                    }
                );
            }

            if (action.get_menu) {
                target.getMenu(action.get_menu, action.from_beginning, action.payload);
                return;
            }

            if (action.go_back) {
                target.goBack();
                return;
            }

            if (action.reset_stack) {
                target.resetStack();
            }
        }
    }
}
