import React from "react";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion';
import "./accordion.scss";
import MenuItem from "./MenuItem";
// import 'react-accessible-accordion/dist/fancy-example.css';

export default class Accordeon extends React.Component {

  constructor(_props) {
    super(_props);
    this.typeName = "Accordeon";
    this.state = {
      menu: {
        menu_items: []
      }
    }
    if (!this.props.data.menu_items) return;
    this.state.menu.menu_items = this.props.data.menu_items.map((x, myIndex) => {
      x.ref = React.createRef();
      x.interactionCounter = 0;
      x.myIndex = myIndex;
      return x;
    });
  }

  componentDidMount() {
    let me = this;
    // let prevState = {... me.this};
    // if (!me.props.data.menu_items) return;
    // prevState.menu.menu_items = me.props.data.menu_items.map((x, myIndex) => {
    //   x.ref = React.createRef();
    //   x.interactionCounter = 0;
    //   x.myIndex = myIndex;
    //   return x;
    // });
    // me.setState(prevState);

  }

  static pad(num, size) {
    num = num.toString();
    while (num.length < size) num = "0" + num;
    return num;
  }

  render() {
    let me = this;
    return (
      <Accordion allowZeroExpanded={true}>
        <AccordionItem>
          <AccordionItemHeading>
            <AccordionItemButton>
              <div className={`accordion-main-trigger ${me.props.index == 0 ? 'round-up' : ''} ${me.props.index == (me.props.amount_of_items - 1) ? 'round-down' : ''}`} >
                <div className={"number"}>{Accordeon.pad(me.props.index , 2)}</div>
                <div className={"accordeon-title"} dangerouslySetInnerHTML={{__html: me.props.data.title}}></div>
              </div>
            </AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel>
            <p style={{color: "var(--black"}}>
              {me.props.data.content}
            </p>
            <br/>
            { me.state.menu.menu_items.map((menuItem, i) => {
              return <MenuItem ref={menuItem.ref} index={i} amount_of_items={me.state.menu.menu_items.length} handleInteraction={me.props.handleInteraction} callback={me.props.callback} data={menuItem} key={`menu-item-${i}-${menuItem.interactionCounter}`}/>
            })
            }
          </AccordionItemPanel>
        </AccordionItem>
      </Accordion>
    );
  }
}