import React from 'react';
import "./lower-menu.scss";
import LowerMenuButton from "./LowerMenuButton";

export default class LowerMenu extends React.Component{

    constructor(props) {
        super(props);
        this.state = {
            menu: [],
            intervalId: null
        }
        this.typeName = "LowerMenu";
        this.getMenu = this.getMenu.bind(this);
        this.activateButton = this.activateButton.bind(this);
    }

    componentDidMount() {
        this.getMenu();
        let intervalId = setInterval(this.getMenu, 25000);
        let prevState = {... this.state};
        prevState.intervalId = intervalId;
        this.setState(prevState);
    }

    componentWillUnmount() {
        clearInterval(this.state.intervalId);
    }

    getMenu() {
        let me = this;
        if (!me.props.user) return;
        fetch(`${this.props.server_url}`,{
            method: 'POST',
            headers: {
                'Content-Type': 'application/json;charset=utf-8'
            }}).then(
            (e) => {return e.json();}
        ).then(
            (e) => {
                let prevState = {... me.state};
                prevState.menu = e;
                for( let i in prevState.menu) {
                    prevState.menu[i].active = false;
                }
                if (prevState.menu.length > 0) prevState.menu[0].active = true;

                this.setState(prevState);
            }
        ).catch((e) => {
            alert(e);
            let prevState = {... me.state};
            prevState.menu = me.props.cacheStorageForData.lowerMenuData.menu;
            for( let i in prevState.menu) {
                prevState.menu[i].active = false;
            }
            prevState.menu[0].active = true;
            me.setState(prevState);
            // alert(e);
        });

    }


    activateButton(e) {
        // alert(7);
        try {
            window.TapticEngine.impact({
                style: "medium" // light | medium | heavy
            });
        } catch(e) {

        }

        let prevState = {... this.state};
        for( let i in prevState.menu) {
            prevState.menu[i].active = false;
        }
        let index = prevState.menu.indexOf(e);
        // alert(index);
        prevState.menu[index].active = true;
        this.setState(prevState);
        if (e.action) {
            this.props.callback(e.action);
        }
    }

    render() {
        let me = this;

        if (me.props.hide_me) {
            return <></>;
        }

        if (!me.props.user) {
            return <></>;
        }
        return (<div className="lower-menu">
            { me.state.menu.map( (e, i) =>
                <LowerMenuButton fontAwesomeClass={e.font_awesome_class} onTouchStart={(ev) => {me.activateButton(e);}} onClick={(ev) => {me.activateButton(e);}} active={e.active} label={e.title} symbol={e.icon_name} key={`lm-${e.id}-${i}`} opacity={this.props.back_button_blocked} />
            )}
        </div>);
    }
}