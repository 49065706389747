import React from "react";
import BurgerMenu from "./BurgerMenu";
import "./header.scss";
import Logo from "./Logo";
import ToastMessage from "./ToastMessage";

export default class Header extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showingToast: false
        };

        this.toastRef = React.createRef();
        this.typeName = "Header";

        this.openCallback = this.openCallback.bind(this);
        this.showToast = this.showToast.bind(this);
    }

    showToast(toast, toastType) {
        if (this.props.disable_toasts) return;
        this.toastRef.current.showMe(toast, toastType);
    }

    openCallback() {
        this.props.callback({
                get_menu: "https://new.tatarstaner.space/users/me",
                from_beginning: true,
                action: {}
            }
        );
    }

    render() {
        let me = this;
        // if (!me.props.user && !me.state.showingToast) {
        //     return <></>;
        // }
        if (me.props.hide_me) {
            return <></>;
        }

        let style = {height: "52px", width: "44px"};

        if (me.props.logo_style) {
            style = JSON.parse(me.props.logo_style);
        }

        return <div className={"header"}>
            {!!me.props.user &&
                <Logo use_alternative_logo={me.props.use_alternative_logo} style={style} />
            }
            {!!me.props.user &&
                <h1 onClick={me.openCallback}>{me.props.user.headerName()}</h1>
            }
            {!!me.props.user && me.props.show_burger_menu &&
                <BurgerMenu />
            }
            <ToastMessage ref={me.toastRef} />
        </div>
    }
}