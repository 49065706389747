import React from "react";
import "./toast-message.scss";

export default class ToastMessage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            content: "",
            shown: false
        };
        this.typeName = "ToastMessage";
        this.showMe = this.showMe.bind(this);
        this.hideMe = this.hideMe.bind(this);
    }

    showMe(content, toastType) {
        let me = this;
        let prevState = {... me.state};
        prevState.shown = true;
        prevState.toastType = toastType;
        prevState.content = content;
        me.setState(prevState, () => {
            setTimeout(me.hideMe, 5000);
        });
    }

    hideMe() {
        let me = this;
        let prevState = {... me.state};
        prevState.shown = false;
        me.setState(prevState);
    }

     render() {
        let me = this;
        let style = {};
        if (me.state.shown) {
            style = {top: 0};
        } else {
            style = {top: "-400%"};
        }

        if ("error" == me.state.toastType) {
            style.background = "var(--red)";
            style.color = "var(--white)";
        }

        if ("success" == me.state.toastType) {
            style.background = "var(--lavender_700)";
            style.color = "var(--white)";
        }

        return <div onClick={me.hideMe} className={"toast-message"} key={`${me.state.toastType}-${me.state.content}`} style={style}>
            <p key={`toast-message-${me.state.content}`}>{me.state.content}</p>
        </div>
    }
}